import { findDOMNode } from 'react-dom'
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'
import React, { Component } from 'react'
import { Container, Segment } from 'semantic-ui-react'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

import Layout from '../components/Layouts/PageLayout'
import PageHeader from '../components/Items/PageHeader'
import HubspotForm from 'react-hubspot-form'

class WpPage extends Component {
  componentDidMount() {
    this.applySemantic()
  }

  componentDidUpdate() {
    this.applySemantic()
  }

  applySemantic = () => {
    Array.from(findDOMNode(this).querySelectorAll('.wp-page p')).forEach(
      node => {
        node.className = 'ui segment basic vertical'
      }
    )

    Array.from(findDOMNode(this).querySelectorAll('.wp-page h2')).forEach(
      node => {
        node.className = 'ui header red'
      }
    )

    Array.from(findDOMNode(this).querySelectorAll('.wp-page form')).forEach(
      node => {
        node.className = 'ui form'
      }
    )

    Array.from(
      findDOMNode(this).querySelectorAll('.wp-page form > div')
    ).forEach(node => {
      node.className = 'sixteen wide field'
    })

    Array.from(
      findDOMNode(this).querySelectorAll('.wp-page input[type=submit]')
    ).forEach(node => {
      node.className = 'ui button red'
    })
  }

  render() {
    const { data, t, pageContext } = this.props
    const {
      featured_media: featuredMedia,
      title,
      content,
      excerpt,
      yoast
    } = data.wordpressPage

    // a way to translate "required" and "senden" field from wordpress
    const contentLoc = content
      .replace(
        /<span>\(erforderlich\)<\/span>/g,
        `<span>(${t('general.required')})</span>`
      )
      .replace(/Senden/g, `${t('general.send')}`)

    // Find HS form snippet and split into multiple parts
    const contentSplitted = contentLoc.split(
      /\[HSFORM id=&#82\d{2};(.*?)&#82\d{2};\]/gi
    )

    const isIE =
      false || (process.browser && document && !!document.documentMode)

    return (
      <>
        <Helmet>
          <title>{title + t('htmlTitleSuffix')}</title>

          <meta name='description' content={yoast ? yoast.metadesc : excerpt} />
          <meta
            name='image'
            content={
              featuredMedia &&
              featuredMedia.localFile &&
              featuredMedia.localFile.childImageSharp.fluid.src
            }
          />

          <meta property='og:title' content={title} />
          <meta
            property='og:description'
            content={yoast ? yoast.metadesc : excerpt}
          />
          <meta
            property='og:image'
            content={
              featuredMedia &&
              featuredMedia.localFile &&
              featuredMedia.localFile.childImageSharp.fluid.src
            }
          />

          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:creator' content='Tischler Reisen' />
          <meta name='twitter:title' content={title} />
          <meta
            name='twitter:description'
            content={yoast ? yoast.metadesc : excerpt}
          />
          <meta
            name='twitter:image'
            content={
              featuredMedia &&
              featuredMedia.localFile &&
              featuredMedia.localFile.childImageSharp.fluid.src
            }
          />
        </Helmet>

        <Layout pageContext={pageContext} className={'page__' + pageContext.slug}>
          {isIE ? (
            <div className='ie-image'>
              {featuredMedia && featuredMedia.localFile && (
                <Img
                  loading='lazy'
                  fadeIn={false}
                  fluid={{
                    ...featuredMedia.localFile.childImageSharp.fluid,
                    aspectRatio: 2.5
                  }}
                  alt={featuredMedia.alt_text || featuredMedia.title}
                />
              )}
            </div>
          ) : (
            featuredMedia &&
            featuredMedia.localFile && (
              <Img
                loading='lazy'
                fadeIn={false}
                fluid={{
                  ...featuredMedia.localFile.childImageSharp.fluid,
                  aspectRatio: 2.5
                }}
                alt={featuredMedia.alt_text || featuredMedia.title}
              />
            )
          )}

          <PageHeader title={title} />

          <Container text>
            <Segment basic>
              {contentSplitted.map((item, index) => {
                return (
                  <div key={index}>
                    {index % 2 ? (
                      <HubspotForm
                        portalId='20039986'
                        formId={item}
                        onSubmit={() => console.log('Submit!')}
                        onReady={form => console.log('Form ready!')}
                        loading={<div>Loading...</div>}
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{ __html: item }}
                        className='wp-page'
                      />
                    )}
                  </div>
                )
              })}
            </Segment>
          </Container>
        </Layout>
      </>
    )
  }
}

export default withTranslation()(WpPage)

export const pageQuery = graphql`
  query WpPageQuery($slug: String!) {
    wordpressPage(slug: { eq: $slug }) {
      wordpress_id
      slug
      title
      content
      excerpt
      yoast {
        metadesc
      }
      featured_media {
        title
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
